import React from "react"
import { Link } from "gatsby"

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)

  const gold = {
    color: "#5E5C4A",
  }

  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <div className="inner">
        <header className="site-head">
          <div className="site-head-container">
            <a
              className="nav-burger"
              href={`#`}
              onClick={() => setToggleNav(!toggleNav)}
            >
              <div
                className="hamburger hamburger--collapse"
                aria-label="Menu"
                role="button"
                aria-controls="navigation"
              >
                <div className="hamburger-box">
                  <div className="hamburger-inner" />
                </div>
              </div>
            </a>
            <nav id="swup" class="site-head-left">
              <ul className="nav" role="menu">
                <li className="nav-home nav-current" role="menuitem">
                  <Link to={`/`}>Home</Link>
                </li>
                <li className="nav-about" role="menuitem">
                  <Link to={`/about`}>About</Link>
                </li>
                <li className="nav-about" role="menuitem">
                  <Link to={`/contact`}>Contact</Link>
                </li>
              </ul>
            </nav>
            <div className="site-head-center">
              <Link className="site-head-logo" to={`/`}>
                {/* {title} */}
                {/* <span style={light}>Ivan </span> */}
                Ivan
                <span style={gold}> Ha</span>
              </Link>
            </div>
            <div className="site-head-right">
              <div className="social-links">
                <a
                  href="https://github.com/ivanha09"
                  title="GitHub"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub
                </a>
                <a
                  href="https://www.linkedin.com/in/ivanha/"
                  title="Linkedin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </div>
            </div>
          </div>
        </header>
        <main id="site-main" className="site-main">
          <div id="swup" className="transition-fade">
            {children}
          </div>
        </main>
        <footer className="site-foot">
          <Link to={`/`}>{title}</Link> &copy; {new Date().getFullYear()}
          {/* &mdash;
        Built with{" "}
          <a
            href="https://gatsbyjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gatsby
        </a> */}
        </footer>
      </div>
    </div>
  )
}

export default Layout
